import React, { useState, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import "../Styles/modelPre.css";

const ModelPreloader = ({ onLoadComplete }) => {
  const [isLoading, setIsLoading] = useState(true);
  const BOT_MODEL_URL =
    "https://d3f67ez5tfvtll.cloudfront.net/Aadhya/Resources/OriginalSiaEnhanced.glb";

  useEffect(() => {
    const preloadModel = async () => {
      try {
        // Preload the model
        await useGLTF.preload(BOT_MODEL_URL);
        setIsLoading(false);
        if (onLoadComplete) {
          onLoadComplete();
        }
      } catch (error) {
        console.error("Error preloading model:", error);
        setIsLoading(false);
      }
    };

    preloadModel();
  }, [onLoadComplete]);

  return (
    <div className={`model-preloader ${!isLoading ? "loaded" : ""}`}>
      {isLoading && (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
          <p className="loading-text ff-m">Loading AI Assistant...</p>
        </div>
      )}
    </div>
  );
};

export default ModelPreloader;
