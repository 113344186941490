import * as THREE from 'three';

const mediaDictionary = {
  "Default": {
    "images": [],
    "videos": [],
    "panoramic": []
  },
  "Living Room": {
    "images": [],
    "videos": [],
    "panoramic": ["Living+Room.webp"],
    "hotspots": [
      { id: 1, position: new THREE.Vector3(3500, -200, -1000), title: 'Dining Area', content: 'Dining Area' },
      // { id: 2, position: new THREE.Vector3(0, 0, -5000), title: 'Bedroom', content: 'Bedroom' },
      // { id: 3, position: new THREE.Vector3(3000, -400, -2000), title: 'Dining Area', content: 'Dining Area' },
      // { id: 4, position: new THREE.Vector3(0, 0, 5000), title: 'Bathroom 1', content: 'Bathroom 1' },
    ]
  },
  "Kitchen": {
    "images": [],
    "videos": [],
    "panoramic": ["Kitchen.webp"],
    "hotspots": [
      // { id: 1, position: new THREE.Vector3(5000, 0, 0), title: 'Living Room', content: 'Living Room' },
       { id: 2, position: new THREE.Vector3(-2000, 0, -3000), title: 'Bedroom', content: 'Bedroom' },
      { id: 3, position: new THREE.Vector3(-1000, 0, 2000), title: 'Living Room', content: 'Living Room' },
      // { id: 4, position: new THREE.Vector3(4000, 0, 1000), title: 'Living Room', content: 'Living Room' },
      { id: 4, position: new THREE.Vector3(-5000, 0, -2000), title: 'Study Room', content: 'Study Room' },

    ]
  },
  "Bedroom": {
    "images": [],
    "videos": [],
    "panoramic": ["Bedroom.webp"],
    "hotspots": [
      // { id: 1, position: new THREE.Vector3(4000, 0, 1000), title: 'Living Room', content: 'Living Room' },
      { id: 2, position: new THREE.Vector3(3000, 0, -3000), title: 'Bathroom 1', content: 'Bathroom 1' },
      // { id: 3, position: new THREE.Vector3(5000, 0, 5000), title: 'Dining Area', content: 'Dining Area' },
      { id: 4, position: new THREE.Vector3(-4000, 0, 1000), title: 'Kitchen', content: 'Kitchen' },
    ]
  },
  "Dining Area": {
    "images": [],
    "videos": [],
    "panoramic": ["Dining.webp"],
    "hotspots": [
      // { id: 1, position: new THREE.Vector3(-5000, 0, 0), title: 'Living Room', content: 'Living Room' },
      { id: 2, position: new THREE.Vector3(3000, 0, 1000), title: 'Kitchen', content: 'Kitchen' },
      { id: 3, position: new THREE.Vector3(1000, 0, -3000), title: 'Living Room', content: 'Living Room' },
      // { id: 4, position: new THREE.Vector3(2000, 2000, -5000), title: 'Bathroom 1', content: 'Bathroom 1' },
    ]
  },
  "Bathroom 1": {
    "images": [],
    "videos": [],
    "panoramic": ["Bathroom.webp"],
    "hotspots": [
      // { id: 1, position: new THREE.Vector3(5000, 0, 0), title: 'Living Room', content: 'Living Room' },
      { id: 2, position: new THREE.Vector3(2500, 1000, 3000), title: 'Bedroom', content: 'Bedroom' },
      // { id: 3, position: new THREE.Vector3(-5000, 0, 0), title: 'Bedroom', content: 'Bedroom' },
      // { id: 4, position: new THREE.Vector3(1000, 0, -5000), title: 'Dining Area', content: 'Dining Area' },
    ]
  },
  "Study Room": {
    "images": [],
    "videos": [],
    "panoramic": ["Study+Room.webp"],
    "hotspots": [
      { id: 3, position: new THREE.Vector3(2500, 1000, -2000), title: 'Kitchen', content: 'Kitchen' },
      { id: 4, position: new THREE.Vector3(-3000, 0, -2000), title: 'Bathroom 2', content: 'Bathroom 2' },
    ]
  },
  "Bathroom 2": {
    "images": [],
    "videos": [],
    "panoramic": ["Bathroom+2.webp"],
    "hotspots": [
      { id: 4, position: new THREE.Vector3(-3000, 0, -2000), title: 'Study Room', content: 'Study Room' },
    ]
  },
};

export default mediaDictionary;
