import { useEffect, useRef } from "react";

const InactivityLogout = ({ onLogout }) => {
  const timeoutIdRef = useRef(null); // Use ref to store the timeout ID
  const INACTIVITY_LIMIT = 100000; // 10 seconds

  const resetTimer = () => {
    // Clear the existing timer
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    // console.log("Timer cleared")

    // Set a new timer
    timeoutIdRef.current = setTimeout(() => {
      // console.warn("LOGOUT")
      onLogout(); // Trigger logout logic
    }, INACTIVITY_LIMIT);
  };

  useEffect(() => {
    // Add event listeners for user activity
    const activityEvents = [
      "mousemove", // Mouse move
      "keydown", // Key presses
      "mousedown", // Mouse clicks
      "scroll", // Scrolling
      "wheel", // Mouse wheel movements
      "touchstart", // Touch start (mobile)
      "touchmove", // Touch move (mobile)
      "touchend", // Touch end (mobile)
      "pointerdown", // Pointer down (touch or mouse)
      "pointermove", // Pointer move (touch or mouse)
    ];

    activityEvents.forEach(event => window.addEventListener(event, resetTimer));

    // Start the initial timer
    resetTimer();

    // Cleanup event listeners and timers
    return () => {
      activityEvents.forEach(event =>
        window.removeEventListener(event, resetTimer)
      );
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return null; // This component does not render anything
};

export default InactivityLogout;
