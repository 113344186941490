import mediaDictionary from "../KeywordMediaMap";

const preloadedMedia = {};

async function preloadFile(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
}

async function preloadMedia() {
  const baseURL = "https://d3f67ez5tfvtll.cloudfront.net/NineReflex/Resources/Media/";

  // Iterate over each keyword in the mediaDictionary
  for (const [keyword, mediaTypes] of Object.entries(mediaDictionary)) {
    preloadedMedia[keyword] = { images: [], videos: [], panoramic: [] };

    // Preload each image file
    for (const image of mediaTypes.images) {
      const imageUrl = `${baseURL}${image}`;
      const blobUrl = await preloadFile(imageUrl);
      preloadedMedia[keyword].images.push(blobUrl);
    }

    // Preload each video file
    for (const video of mediaTypes.videos) {
      const videoUrl = `${baseURL}${video}`;
      const blobUrl = await preloadFile(videoUrl);
      preloadedMedia[keyword].videos.push(blobUrl);
    }

    // Preload each panoramic file
    for (const panoramic of mediaTypes.panoramic) {
      const panoramicUrl = `${baseURL}${panoramic}`;
      const blobUrl = await preloadFile(panoramicUrl);
      preloadedMedia[keyword].panoramic.push(blobUrl);
    }
  }
}

// Run the preload function on module load
preloadMedia().catch(error => console.error('Error preloading media:', error));

// Export the preloaded media object for use in other components
export { preloadedMedia };