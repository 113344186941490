const LivingAreaData = [
    {
        "time": 187,
        "type": "word",
        "start": 0,
        "end": 3,
        "value": "Hey"
    },
    {
        "time": 187,
        "type": "viseme",
        "value": "k"
    },
    {
        "time": 300,
        "type": "viseme",
        "value": "e"
    },
    {
        "time": 500,
        "type": "viseme",
        "value": "sil"
    },
    {
        "time": 1117,
        "type": "word",
        "start": 5,
        "end": 8,
        "value": "I'm"
    },
    {
        "time": 1117,
        "type": "viseme",
        "value": "a"
    },
    {
        "time": 1167,
        "type": "viseme",
        "value": "p"
    },
    {
        "time": 1242,
        "type": "word",
        "start": 9,
        "end": 12,
        "value": "Sia"
    },
    {
        "time": 1242,
        "type": "viseme",
        "value": "s"
    },
    {
        "time": 1342,
        "type": "viseme",
        "value": "i"
    },
    {
        "time": 1430,
        "type": "viseme",
        "value": "i"
    },
    {
        "time": 1492,
        "type": "viseme",
        "value": "a"
    },
    {
        "time": 1680,
        "type": "viseme",
        "value": "sil"
    },
    {
        "time": 1817,
        "type": "word",
        "start": 14,
        "end": 18,
        "value": "your"
    },
    {
        "time": 1817,
        "type": "viseme",
        "value": "i"
    },
    {
        "time": 1867,
        "type": "viseme",
        "value": "O"
    },
    {
        "time": 1930,
        "type": "viseme",
        "value": "r"
    },
    {
        "time": 2080,
        "type": "word",
        "start": 19,
        "end": 22,
        "value": "A.I"
    },
    {
        "time": 2080,
        "type": "viseme",
        "value": "e"
    },
    {
        "time": 2180,
        "type": "viseme",
        "value": "a"
    },
    {
        "time": 2392,
        "type": "word",
        "start": 23,
        "end": 28,
        "value": "guide"
    },
    {
        "time": 2392,
        "type": "viseme",
        "value": "k"
    },
    {
        "time": 2492,
        "type": "viseme",
        "value": "a"
    },
    {
        "time": 2617,
        "type": "viseme",
        "value": "t"
    },
    {
        "time": 2655,
        "type": "word",
        "start": 29,
        "end": 31,
        "value": "to"
    },
    {
        "time": 2655,
        "type": "viseme",
        "value": "t"
    },
    {
        "time": 2692,
        "type": "viseme",
        "value": "u"
    },
    {
        "time": 2805,
        "type": "word",
        "start": 32,
        "end": 36,
        "value": "Jain"
    },
    {
        "time": 2805,
        "type": "viseme",
        "value": "S"
    },
    {
        "time": 2892,
        "type": "viseme",
        "value": "E"
    },
    {
        "time": 3017,
        "type": "viseme",
        "value": "t"
    },
    {
        "time": 3167,
        "type": "word",
        "start": 37,
        "end": 43,
        "value": "Aadhya"
    },
    {
        "time": 3167,
        "type": "viseme",
        "value": "a"
    },
    {
        "time": 3192,
        "type": "viseme",
        "value": "t"
    },
    {
        "time": 3242,
        "type": "viseme",
        "value": "t"
    },
    {
        "time": 3317,
        "type": "viseme",
        "value": "i"
    },
    {
        "time": 3392,
        "type": "viseme",
        "value": "a"
    },
    {
        "time": 3567,
        "type": "viseme",
        "value": "sil"
    },
    {
        "time": 4147,
        "type": "word",
        "start": 45,
        "end": 48,
        "value": "How"
    },
    {
        "time": 4147,
        "type": "viseme",
        "value": "k"
    },
    {
        "time": 4197,
        "type": "viseme",
        "value": "a"
    },
    {
        "time": 4335,
        "type": "word",
        "start": 49,
        "end": 52,
        "value": "can"
    },
    {
        "time": 4335,
        "type": "viseme",
        "value": "k"
    },
    {
        "time": 4410,
        "type": "viseme",
        "value": "a"
    },
    {
        "time": 4460,
        "type": "viseme",
        "value": "t"
    },
    {
        "time": 4535,
        "type": "word",
        "start": 53,
        "end": 54,
        "value": "I"
    },
    {
        "time": 4535,
        "type": "viseme",
        "value": "a"
    },
    {
        "time": 4710,
        "type": "word",
        "start": 55,
        "end": 61,
        "value": "assist"
    },
    {
        "time": 4710,
        "type": "viseme",
        "value": "@"
    },
    {
        "time": 4772,
        "type": "viseme",
        "value": "s"
    },
    {
        "time": 4897,
        "type": "viseme",
        "value": "i"
    },
    {
        "time": 4960,
        "type": "viseme",
        "value": "s"
    },
    {
        "time": 5022,
        "type": "viseme",
        "value": "t"
    },
    {
        "time": 5110,
        "type": "word",
        "start": 62,
        "end": 65,
        "value": "you"
    },
    {
        "time": 5110,
        "type": "viseme",
        "value": "i"
    },
    {
        "time": 5272,
        "type": "viseme",
        "value": "u"
    },
    {
        "time": 5397,
        "type": "viseme",
        "value": "sil"
    }
]

export default LivingAreaData
