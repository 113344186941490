import React from "react";

const TypingAnimation = () => {
  return (
    <div>
      <div className="typing-animation">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
    </div>
  );
};

export default TypingAnimation;
