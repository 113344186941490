import React, { useState, useEffect } from 'react';
import { GoHeart } from "react-icons/go";
import { GoHeartFill } from "react-icons/go";
import { IoIosShareAlt } from "react-icons/io";

const ConfirmationMessage = ({ message, onClose }) => {
  const [timer, setTimer] = useState(2);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    if (timer === 0) {
      onClose();
    }

    return () => clearInterval(interval);
  }, [timer, onClose]);

  return (
    <div style={styles.confirmationMessageContainer}>
      <h2 style={styles.confirmationMessageText}>{message}</h2>
      <button style={styles.closeButton} onClick={onClose} disabled={timer > 0}>
        Close ({timer}s)
      </button>
    </div>
  );
};

const CustomCallbackPopup = ({
  onClose,
  onRequestCallback,
  isLiked,
  onLikeClick,
  onShareClick,
  userName,
  userMobileNumber
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleRequestCallback = () => {
    onRequestCallback();
    setShowConfirmation(true);
    setTimeout(() => {
      setShowConfirmation(false);
      onClose();
    }, 2000); // 2000 milliseconds = 2 seconds
  };

  return (
    <div style={styles.customPopup} className='dropdown-cont2'>
      <div style={styles.customPopupContent}>
        {showConfirmation ? (
          <ConfirmationMessage
            message="Your request for a callback has been successfully received."
            onClose={() => setShowConfirmation(false)}
          />
        ) : (
          <>
            <h2 style={styles.headerText}>Do you want to request a callback?</h2>
            <div style={styles.buttonContainer}>
              <button style={styles.requestCallbackButton} onClick={handleRequestCallback}>
                Request Call Back
              </button>
              <button style={styles.noThanksButton} onClick={onClose}>
                No, Thanks
              </button>
            </div>
            <div style={styles.iconContainer}>
              {isLiked ? (
                <GoHeartFill color='white'
                  style={styles.likeIconFilled}
                  onClick={onLikeClick}
                />
              ) : (
                <GoHeartFill color='red'
                  style={styles.likeIcon}
                  onClick={onLikeClick}
                />
              )}
              <IoIosShareAlt
                style={styles.shareIcon}
                onClick={onShareClick}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  customPopup: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.55) !important',
    padding: '10px',
    borderRadius: '7px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    zIndex: '10000',
    textAlign: 'center',
    width: '80%',
    maxWidth: '20rem',
    fontFamily: 'Clash Display Regular',
    fontSize: '0.8rem',
    height: 'auto',
  },
  customPopupContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.55) !important',
  },
  headerText: {
    fontSize: '1rem',
    marginBottom: '10px',
  },
  buttonContainer: {
    marginTop: '5px',
  },
  requestCallbackButton: {
    backgroundColor: 'beige',
    color: 'black',
    border: 'none',
    borderRadius: '30px',
    fontFamily: 'Clash Display Regular',
    padding: '8px 16px',
    margin: '6px',
    cursor: 'pointer',
  },
  noThanksButton: {
    backgroundColor: 'transparent',
    color: 'beige',
    border: '1px solid beige',
    borderRadius: '30px',
    padding: '8px 16px',
    margin: '6px',
    fontFamily: 'Clash Display Regular',
    cursor: 'pointer',
  },
  iconContainer: {
    marginTop: '10px',
    display: 'flex',
    gap: '10px',
  },
  likeIcon: {
    fontSize: '20px',
    color: 'white',
    cursor: 'pointer',
    marginTop: '3px'

  },
  likeIconFilled: {
    fontSize: '20px',
    color: 'red',
    cursor: 'pointer',
    marginTop: '3px'

  },
  shareIcon: {
    fontSize: '24px',
    color: 'white',
    cursor: 'pointer',
  },
  confirmationMessageContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.55) !important',
    color: 'beige',
    padding: '20px',
    borderRadius: '7px',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmationMessageText: {
    fontSize: '1rem',
    marginBottom: '10px',
  },
  closeButton: {
    backgroundColor: 'beige',
    color: 'black',
    border: 'none',
    borderRadius: '30px',
    fontFamily: 'Clash Display Regular',
    padding: '8px 16px',
    cursor: 'pointer',
  },
};

export default CustomCallbackPopup;


