import React, { useState, useMemo, useRef } from "react";
import axios from "axios";
import { useTable } from "react-table";

const FilterPopup = ({ onClose, onFilterSelected }) => {
  const [selectedFloor, setSelectedFloor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [availableSizes, setAvailableSizes] = useState([]);
  const [showOkayMessage, setShowOkayMessage] = useState(false);
  const [backendResponse, setBackendResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const floorSizes = {
    "4th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "5th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "6th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "7th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "8th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "9th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "10th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "11th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "12th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "13th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "14th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "15th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "16th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "17th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "18th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
    "19th": [586, 626, 638, 896, 918, 927, 915, 917, 930, 935],
  };

  const [shake, setShake] = useState(false);
  const popupRef = useRef(null);

  const handleBackgroundClick = (e) => {
    if (!shake && popupRef.current && !popupRef.current.contains(e.target)) {
      setShake(true);
      setTimeout(() => setShake(false), 500); // Reset after animation
    }
  };

  const handleFloorChange = (event) => {
    const floor = event.target.value;
    setSelectedFloor(floor);
    setAvailableSizes(floorSizes[floor] || []);
    setSelectedSize(""); // Reset selected size when floor changes
  };

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const url = "https://api.ninereflex.xrvizion.com";

  const handleOkayClick = async () => {
    if (selectedFloor && selectedSize) {
      setIsLoading(true);
      try {
        const response = await axios.post(`${url}/user/calculatecostsheet`, {
          floor: parseInt(selectedFloor.split(" ")[0], 10), // Convert floor to a number
          size: selectedSize,
        });
        onFilterSelected(selectedFloor, selectedSize);
        const formattedResponse = Object.keys(response.data)
          .filter((key) => key !== "msg")
          .map((key) => ({
            name:
              key === "grandTotal" ? (
                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}</strong>
              ) : (
                key.charAt(0).toUpperCase() + key.slice(1)
              ),
            cost: response.data[key].toLocaleString(), // Add commas to the cost
            isGrandTotal: key === "grandTotal",
          }));
        setBackendResponse(formattedResponse);

        // Show loading for 2 seconds
        setTimeout(() => {
          setIsLoading(false);
          setShowOkayMessage(true);

          // Add a timeout to reset the form after showing the message
          setTimeout(() => {
            setShowOkayMessage(false);
            setSelectedFloor("");
            setSelectedSize("");
            setAvailableSizes([]);
            setBackendResponse([]);
          }, 100000); // Reset after 30 seconds
        }, 2000); // Loading duration
      } catch (error) {
        console.error("Error sending data to backend:", error);
        setIsLoading(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        style: {
          textAlign: "left",
          padding: "4px 8px",
          border: "1px solid #ddd",
          fontSize: "14px",
          "@media (max-width: 480px)": {
            fontSize: "12px",
            padding: "2px 4px",
          },
        },
        Cell: ({ value, row }) =>
          row.original.isGrandTotal ? (
            <strong style={{ textAlign: "left" }}>{value}</strong>
          ) : (
            value
          ),
      },
      {
        Header: "Cost",
        accessor: "cost",
        style: {
          textAlign: "left",
          padding: "4px 8px",
          border: "1px solid #ddd",
          fontSize: "14px",
          "@media (max-width: 480px)": {
            fontSize: "12px",
            padding: "2px 4px",
          },
        },
      },
    ],
    []
  );

  const data = useMemo(() => backendResponse, [backendResponse]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="popup-cont" onClick={handleBackgroundClick}>
      <div
        style={
          showOkayMessage
            ? styles.popupContainerResponse
            : styles.popupContainer
        }
        ref={popupRef}
        className={`popup-container ${shake ? "shake" : ""}`}
      >
        <button onClick={onClose} style={styles.closeButton}>
          &times;
        </button>
        <div style={styles.popupContent}>
          <h6 style={styles.title}>Calculate Cost Sheet</h6>
          {isLoading ? (
            <div style={styles.loadingContainer}>
              <p style={styles.loadingText}>Loading...</p>
            </div>
          ) : (
            <>
              <div style={styles.inputContainer}>
                <label style={styles.label}>Floor:</label>
                <select
                  value={selectedFloor}
                  onChange={handleFloorChange}
                  style={styles.input}
                >
                  <option value="" disabled>
                    Select floor
                  </option>
                  {Object.keys(floorSizes).map((floor) => (
                    <option key={floor} value={floor}>
                      {floor} Floor
                    </option>
                  ))}
                </select>
              </div>

              <div style={styles.inputContainer}>
                <label style={styles.label}>Size:</label>
                <select
                  value={selectedSize}
                  onChange={handleSizeChange}
                  style={styles.input}
                  disabled={!selectedFloor}
                >
                  <option value="" disabled>
                    Select Size
                  </option>
                  {availableSizes.map((size) => (
                    <option key={size} value={size}>
                      {size} sq ft
                    </option>
                  ))}
                </select>
              </div>
              <button
                onClick={handleOkayClick}
                style={
                  selectedFloor && selectedSize
                    ? styles.submitButton
                    : styles.submitButtonDisabled
                }
                disabled={!selectedFloor || !selectedSize}
              >
                Submit
              </button>
              {showOkayMessage && (
                <div style={styles.tableContainer}>
                  <table {...getTableProps()} style={styles.table}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps()}
                              style={styles.tableHeader}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            style={
                              row.original.isGrandTotal
                                ? styles.grandTotalRow
                                : styles.tableRow
                            }
                          >
                            {row.cells.map((cell) => (
                              <td
                                {...cell.getCellProps()}
                                style={
                                  row.original.isGrandTotal
                                    ? styles.grandTotalCell
                                    : styles.tableCell
                                }
                              >
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  popupContainer: {
    position: "fixed",
    fontFamily: "Clash Display Regular",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    webkitBackdropFilter: "blur(10px)",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(0, 0, 0, 0.55)",
    padding: "16px",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    zIndex: "10000",
    textAlign: "left",
    width: "90%",
    maxWidth: "320px",
    color: "white",
  },
  popupContainerResponse: {
    position: "fixed",
    fontFamily: "Clash Display Regular",
    top: "35%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    webkitBackdropFilter: "blur(10px)",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(0, 0, 0, 0.55)",
    padding: "16px",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    zIndex: "10000",
    textAlign: "left",
    width: "90%",
    maxWidth: "320px",
    color: "white",
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    width: "100%",
  },
  label: {
    flex: "0 0 25%",
    fontSize: "14px",
    fontFamily: "Clash Display Regular",
    color: "white",
    marginRight: "8px",
  },
  input: {
    flex: "1",
    padding: "4px 8px",
    borderRadius: "5px",
    fontSize: "14px",
    fontFamily: "Clash Display Regular",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.55)",
    border: "1px solid rgba(255, 255, 255, 0.2)",
  },
  submitButton: {
    padding: "6px 12px",
    backgroundColor: "beige",
    color: "black",
    borderRadius: "1rem",
    cursor: "pointer",
    fontSize: "14px",
    border: "none",
    fontFamily: "Clash Display Regular",
    fontWeight: "bold",
    alignSelf: "center",
    marginTop: "8px",
    marginBottom: "12px",
  },
  submitButtonDisabled: {
    padding: "6px 12px",
    backgroundColor: "#ccc",
    color: "black",
    borderRadius: "1rem",
    cursor: "not-allowed",
    fontSize: "14px",
    border: "none",
    fontFamily: "Clash Display Regular",
    fontWeight: "bold",
    alignSelf: "center",
    marginTop: "8px",
    marginBottom: "12px",
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "8px",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
  tableHeader: {
    textAlign: "left",
    padding: "4px 8px",
    borderBottom: "2px solid rgba(255, 255, 255, 0.2)",
    fontSize: "14px",
    "@media (max-width: 480px)": {
      fontSize: "12px",
      padding: "2px 4px",
    },
  },
  tableRow: {
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  },
  tableCell: {
    textAlign: "left",
    padding: "4px 8px",
    fontSize: "14px",
    "@media (max-width: 480px)": {
      fontSize: "12px",
      padding: "2px 4px",
    },
  },
  grandTotalRow: {
    backgroundColor: "rgba(245, 245, 220, 0.3)",
    borderBottom: "none",
  },
  grandTotalCell: {
    backgroundColor: "rgba(245, 245, 220, 0.3)",
    color: "white",
    textAlign: "left",
    padding: "4px 8px",
    fontSize: "14px",
    fontWeight: "bold",
    "@media (max-width: 480px)": {
      fontSize: "12px",
      padding: "2px 4px",
    },
  },
  closeButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
    color: "white",
    padding: "4px",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Clash Display Regular",
    color: "white",
    margin: "0 0 16px 0",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0",
  },
  loadingText: {
    fontSize: "14px",
    fontFamily: "Clash Display Regular",
    color: "white",
  },
};

export default FilterPopup;
