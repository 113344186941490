import React, { useEffect, useRef, useState } from 'react'

import anime from "animejs";

import mediaDictionary from './KeywordMediaMap';

import ScrollContainer from 'react-indiana-drag-scroll'

import { BsArrowRightCircle } from "react-icons/bs";
import { BsArrowLeftCircle } from "react-icons/bs";


import { IoArrowBackCircleOutline } from "react-icons/io5";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IoPlayOutline } from "react-icons/io5";
import { IoPauseOutline } from "react-icons/io5";

import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";


import { IoSparkles } from "react-icons/io5";


import '../Styles/navigationStyle.css'

const Navigation = ({ navigationProp, toggleAIChat, setToggleAIChat, updateMedia, keywordsToPlay, setKeywordsToPlay }) => {

  const itemInterval = useRef()
  const keywordItemInterval = useRef()
  const [slideShowPlaying, setSlideShowPlaying] = useState(false)
  const [keywordSlideShowPlaying, setKeywordSlideshowPlaying] = useState(false)

  const scrollContainerRef = useRef(null);
  const playButtonContainerRef = useRef(null);

  useEffect(() => {
    console.warn("keywordsToPlay", keywordsToPlay)
    playMediaWithKeywords()
  }, [keywordsToPlay])

  const playMediaWithKeywords = () => {
    if (!keywordsToPlay || keywordsToPlay.length === 0) {
      console.warn("No keywords available to play");
      return;
    }
  
    let keywordIndex = 1; // Start from the second item for the interval
    let intervalTime = (navigationProp.audioDuration / keywordsToPlay.length) * 1000;
    console.warn("intervalTime", intervalTime);
    
    setKeywordSlideshowPlaying(true);
    setSlideShowPlaying(false);
    clearInterval(itemInterval.current);
  
    // Play the first keyword immediately
    const firstKeyword = keywordsToPlay[0];
    console.log(`Playing media for first keyword: ${firstKeyword}`);
    
    const [mainMenuKey, subMenuKey] = Object.entries(navigationProp.submenuItems).find(([mainMenu, subItems]) =>
      subItems.includes(firstKeyword)
    ) || [];
  
    if (mainMenuKey && subMenuKey) {
      navigationProp.setCurrentSubItem(firstKeyword);
      navigationProp.setActiveSubMenu(mainMenuKey);
      playSubItem(firstKeyword);
    } else {
      console.warn("First keyword not found in submenu items:", firstKeyword);
    }
  
    // Set up the interval for remaining keywords
    keywordItemInterval.current = setInterval(() => {
      if (keywordIndex >= keywordsToPlay.length) {
        clearInterval(keywordItemInterval.current); // Stop when all keywords are played
        console.log("Finished playing all keywords.");
        setKeywordSlideshowPlaying(false);
        return;
      }
  
      const keyword = keywordsToPlay[keywordIndex];
      console.log(`Playing media for keyword: ${keyword}`);
  
      const [mainMenuKey, subMenuKey] = Object.entries(navigationProp.submenuItems).find(([mainMenu, subItems]) =>
        subItems.includes(keyword)
      ) || [];
  
      if (!mainMenuKey || !subMenuKey) {
        console.warn("Keyword not found in submenu items:", keyword);
        keywordIndex++;
        return; // Skip to the next keyword
      }
  
      navigationProp.setCurrentSubItem(keyword);
      navigationProp.setActiveSubMenu(mainMenuKey);
      playSubItem(keyword);
  
      keywordIndex++;
    }, intervalTime);
  };
  



  useEffect(() => {
    if (navigationProp.showSubItems) {
      // console.log("Interval started")
      // itemInterval.current = setInterval(() => {
      //   console.warn("Auto next")
      //   const nextBtn = document.getElementById("next-btn")
      //   nextBtn.click()
      // }, 5000);
    }
    else {
      // clearInterval(itemInterval.current)
      // console.log("Interval cleared")
    }
  }, [navigationProp.showSubItems, navigationProp.menuItems])

  const startSlideShow = () => {
    if (slideShowPlaying || keywordSlideShowPlaying) {
      clearInterval(itemInterval.current)
      setSlideShowPlaying(false)
      clearInterval(keywordItemInterval.current)
      setKeywordSlideshowPlaying(false)
    }
    else {
      setSlideShowPlaying(true)
      console.log("Interval started")
      itemInterval.current = setInterval(() => {
        console.warn("Auto next")
        const nextBtn = document.getElementById("next-btn")
        nextBtn.click()
      }, 7000);
    }
  }



  const handleSubItems = (data) => {
    console.log(data)
    navigationProp.setShowSubItems(true)

    if (data === "Walkthrough video") {
      navigationProp.setMenuItems(["Walkthrough video"])
      playSubItem("Walkthrough video")
    }
    else if (data === "Apartment") {
      navigationProp.setMenuItems(["Living room", "Kitchen", "Bedroom", "Dining room", "Bathroom"])
      playSubItem("Living room")
    }
    else if (data === "Amenities") {
      navigationProp.setMenuItems([
        "Yoga deck",
        "Party lawn",
        "Cricket net",
        "Car charging point",
        "Clubhouse",
        "Tennis",
        "Billiards",
        "Gym",
        "Multipurpose hall",
        "Lobby",
        "Lift"
      ]);
      playSubItem("Yoga deck")
    }
    else if (data === "Location") {
      navigationProp.setMenuItems([
        "Location",
        "Top view",
        "Side view"
      ]);
      playSubItem("Location")
    }
    else if (data === "Miscellaneous") {
      navigationProp.setMenuItems([
        "Elevation"
      ]);
      playSubItem("Elevation")
    }
  }


  const handleShowDefaultItems = () => {
    clearInterval(itemInterval.current)
    setSlideShowPlaying(false)
    navigationProp.setShowSubItems(false)
    navigationProp.setMenuItems(navigationProp.defaultMenu)
    playSubItem("Default")
  }

  useEffect(() => {
    if (updateMedia.length) {
      playSubItem(updateMedia)
    }
  }, [updateMedia])


  const playSubItem = (data) => {
    // Set the current sub-item
    console.log(data)

    navigationProp.setCurrentSubItem(data);
    navigationProp.setPlayIndex(0)

    // Initialize an empty array to hold the media list
    const newMediaList = [];

    // Add images to the media list if they exist
    if (mediaDictionary[data].images.length) {
      newMediaList.push(...mediaDictionary[data].images);
    }

    // Add videos to the media list if they exist
    if (mediaDictionary[data].videos.length) {
      newMediaList.push(...mediaDictionary[data].videos);
    }

    if (mediaDictionary[data].panoramic.length) {
      newMediaList.push(...mediaDictionary[data].panoramic);
    }

    // Set the media list to the state
    navigationProp.setMediaList(newMediaList);
  };

  const playNextMedia = () => {
    // Find the current submenu items
    const currentMainMenu = Object.keys(navigationProp.submenuItems).find(mainMenu =>
      navigationProp.submenuItems[mainMenu].includes(navigationProp.currentSubItem)
    );

    if (!currentMainMenu) {
      console.error("Current submenu item not found in submenuItems");
      return;
    }

    const submenuItems = navigationProp.submenuItems[currentMainMenu]; // All submenu items for the current main menu
    const currentIndex = submenuItems.indexOf(navigationProp.currentSubItem);

    if (currentIndex === -1) {
      console.error("Current submenu item is not valid");
      return;
    }

    const mainMenus = Object.keys(navigationProp.submenuItems); // Get all main menus
    let nextMainMenuIndex = mainMenus.indexOf(currentMainMenu);
    let nextSubItemIndex = currentIndex + 1;

    while (true) {
      const submenuItems = navigationProp.submenuItems[mainMenus[nextMainMenuIndex]];

      // If the submenu has items, check for the next item
      if (submenuItems.length > 0) {
        // If the next item exists in the current submenu
        if (nextSubItemIndex < submenuItems.length) {
          const nextSubItem = submenuItems[nextSubItemIndex];
          navigationProp.setCurrentSubItem(nextSubItem); // Update currentSubItem

          // Populate the mediaList with media from the next submenu item
          const nextMedia = mediaDictionary[nextSubItem];
          const newMediaList = [
            ...(nextMedia?.images || []),
            ...(nextMedia?.videos || []),
            ...(nextMedia?.panoramic || []),
          ];

          navigationProp.setMediaList(newMediaList); // Update mediaList
          playSubItem(nextSubItem); // Play the next submenu item
          navigationProp.setActiveSubMenu(mainMenus[nextMainMenuIndex]);
          return;
        }
      }
      console.log("not coming here")
      // Move to the next main menu
      nextMainMenuIndex = (nextMainMenuIndex + 1) % mainMenus.length; // Loop to the first main menu if needed
      nextSubItemIndex = 0; // Start from the first submenu item of the next main menu

      // Break condition: If we come back to the same main menu and submenu
      if (
        mainMenus[nextMainMenuIndex] === currentMainMenu &&
        nextSubItemIndex === currentIndex + 1
      ) {
        console.log("No valid submenu items to play.");
        return;
      }

      // Update the active main menu
      navigationProp.setActiveSubMenu(mainMenus[nextMainMenuIndex]);
      console.log(mainMenus[nextMainMenuIndex])
    }
  };

  const playPrevMedia = () => {
    // Find the current submenu items
    const currentMainMenu = Object.keys(navigationProp.submenuItems).find(mainMenu =>
      navigationProp.submenuItems[mainMenu].includes(navigationProp.currentSubItem)
    );

    if (!currentMainMenu) {
      console.error("Current submenu item not found in submenuItems");
      return;
    }

    const submenuItems = navigationProp.submenuItems[currentMainMenu]; // All submenu items for the current main menu
    const currentIndex = submenuItems.indexOf(navigationProp.currentSubItem);

    if (currentIndex === -1) {
      console.error("Current submenu item is not valid");
      return;
    }

    const mainMenus = Object.keys(navigationProp.submenuItems); // Get all main menus
    let prevMainMenuIndex = mainMenus.indexOf(currentMainMenu);
    let prevSubItemIndex = currentIndex - 1;

    while (true) {
      const submenuItems = navigationProp.submenuItems[mainMenus[prevMainMenuIndex]];

      // If the submenu has items, check for the previous item
      if (submenuItems.length > 0) {
        // If the previous item exists in the current submenu
        if (prevSubItemIndex >= 0) {
          const prevSubItem = submenuItems[prevSubItemIndex];
          navigationProp.setCurrentSubItem(prevSubItem); // Update currentSubItem

          // Populate the mediaList with media from the previous submenu item
          const prevMedia = mediaDictionary[prevSubItem];
          const newMediaList = [
            ...(prevMedia?.images || []),
            ...(prevMedia?.videos || []),
            ...(prevMedia?.panoramic || []),
          ];

          navigationProp.setMediaList(newMediaList); // Update mediaList
          playSubItem(prevSubItem); // Play the previous submenu item
          navigationProp.setActiveSubMenu(mainMenus[prevMainMenuIndex]);
          return;
        }
      }

      // Move to the previous main menu
      prevMainMenuIndex =
        (prevMainMenuIndex - 1 + mainMenus.length) % mainMenus.length; // Loop to the last main menu if needed
      prevSubItemIndex =
        navigationProp.submenuItems[mainMenus[prevMainMenuIndex]].length - 1; // Start from the last submenu item of the previous main menu

      // Break condition: If we come back to the same main menu and submenu
      if (
        mainMenus[prevMainMenuIndex] === currentMainMenu &&
        prevSubItemIndex === currentIndex - 1
      ) {
        console.log("No valid submenu items to play.");
        return;
      }

      // Update the active main menu
      navigationProp.setActiveSubMenu(mainMenus[prevMainMenuIndex]);
    }
  };

  const scrollToActiveElement = () => {
    const container = document.getElementsByClassName("scroll-container")[0]
    const activeElement = container.querySelector('.active'); // Adjust selector for your active element

    console.log(activeElement)
  
    if (activeElement) {
      const containerRect = container.getBoundingClientRect();
      const activeRect = activeElement.getBoundingClientRect();
  
      // Calculate the scroll position to center the active element
      const scrollOffset = activeRect.left - containerRect.left - (container.clientWidth / 2) + (activeElement.clientWidth / 2);
  
      // Smooth scroll to the calculated position
      container.scrollTo({
        left: container.scrollLeft + scrollOffset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToActiveElement()
    }, 100);
  }, [navigationProp.currentSubItem])
  

  const handleUserInteraction = (event) => {
    const targetId = event.target.id;
    // console.log("Interacted", event.target.id)
    if (targetId === 'next-btn' || targetId === 'prev-btn' || targetId.includes('-id') === true) {
      clearInterval(itemInterval.current);
      clearInterval(keywordItemInterval.current)
      setSlideShowPlaying(false)
      setKeywordSlideshowPlaying(false)
      // console.log("interval cleared")
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleUserInteraction);
    document.addEventListener('touchstart', handleUserInteraction);

    const rightPos = document.getElementsByClassName('nav-menu')[0]
    // const chatBtn = document.getElementsByClassName('chat-toggler')[0]

    // chatBtn.style.right = rightPos.getBoundingClientRect().x + "px"
    // console.log(rightPos.getBoundingClientRect().x)
    // Cleanup interval and event listeners on component unmount
    return () => {
      clearInterval(itemInterval.current)
      setSlideShowPlaying(false)
      document.removeEventListener('mousedown', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
    };
  }, []);

  const displaySubMenuTitle = (val) => {
    const map = {
      "Third Floor": "Type 1",
      "Fourth Floor": "Type 2",
      "Fifth Floor": "Type 3",
      "Nineteenth Floor": "Type 4",
      "Unit Plan 601": "Type 1",
      "Unit Plan 611": "Type 2",
      "Unit Plan 612": "Type 3",
      "Unit Plan 613": "Type 4",
      "Unit Plan 626": "Type 5"
    };

    return map[val] || val; // Return mapped value if exists, otherwise return the original value
  };

  const updatePlayButtonPosition = () => {
    const scrollContainer = scrollContainerRef.current;
    const playButtonContainer = playButtonContainerRef.current;

    if (!scrollContainer || !playButtonContainer) return;

    if (window.innerWidth > 768) {
      const scrollContainerRect = scrollContainer.getBoundingClientRect();

      // Convert 3rem to pixels
      const remToPx = parseFloat(getComputedStyle(document.documentElement).fontSize) * 3.5;

      playButtonContainer.style.position = "absolute";
      playButtonContainer.style.left = `${scrollContainerRect.left - remToPx}px`;
    } else {
      playButtonContainer.style.left = ""; // Reset style for smaller screens
    }
  };

  useEffect(() => {
    // Add resize event listener
    window.addEventListener("resize", updatePlayButtonPosition);

    // Call once on component mount to set the initial position
    updatePlayButtonPosition();

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", updatePlayButtonPosition);
    };
  }, []); // Empty dependency array ensures this runs once

  return (
    <>
      <div className='overlay' style={{ zIndex: "0" }}></div>

      {
        true
        &&
        <div
          style={{
            position: "absolute",
            top: "45%",
            transform: "translateY(-50%)",
            width: "100%",
            zIndex: 10
          }}
        >
          <button
            onClick={playPrevMedia}
            id='prev-btn'
            style={{
              position: "absolute",
              zIndex: "5",
              cursor: "pointer",
              background: "transparent",
              border: "none"
            }}
          >
            <IoIosArrowBack
              size={50}
              color='#ffffff95'
              style={{
                pointerEvents: "none"
              }}
            />
          </button>

          <button
            onClick={playNextMedia}
            id='next-btn'
            style={{
              position: "absolute",
              zIndex: "3",
              cursor: "pointer",
              background: "transparent",
              border: "none",
            }}
          >
            <IoIosArrowForward
              size={50}
              color='#ffffff95'
              style={{
                pointerEvents: "none"
              }}
            />
          </button>
        </div>
      }



      {
        navigationProp.showSubItems
        &&
        <>
          <div className="ff-m fs-xs" style={{
            position: "absolute",
            zIndex: "3",
            top: "2rem",
            left: "50%",
            transform: "translateX(-50%)",
            color: "white",
            background: "#00000050",
            padding: "0.5rem 1.5rem",
            borderRadius: "0.5rem",
            border: "1px solid #fff"
          }}>{navigationProp.currentSubItem}</div>
        </>
      }

      {
        <div className={`play-btn-cont ${navigationProp.siteOnlyVersion.current && 'siteonly'}`} ref={playButtonContainerRef} onClick={startSlideShow}>
          {
            slideShowPlaying || keywordSlideShowPlaying
              ?
              <img src='https://xrv-xrc.s3.ap-south-1.amazonaws.com/Aadhya/Resources/Pause+(2).png' alt='' />

              :
              <img src='https://xrv-xrc.s3.ap-south-1.amazonaws.com/Aadhya/Resources/Play+(4).png' alt='' />
          }
        </div>
      }

      <ScrollContainer
        className={`scroll-container ${navigationProp.siteOnlyVersion.current && 'siteonly'}`}
        horizontal={true}
        style={{
          // marginLeft: "0.5rem",
          position: "absolute",
          zIndex: "2",
          transition: "transform2 1s ease-out",
          maskImage: "linear-gradient(to left, transparent 0%, black 15%, black 85%, transparent 100%), linear-gradient(to right, transparent 0%, black 15%, black 85%, transparent 100%)",
        }}
      >


        <div className={`nav-menu navigation-cont ${navigationProp.siteOnlyVersion.current && 'siteonly'}`} key={navigationProp.refreshNavKey} ref={scrollContainerRef}>

          {
            navigationProp.showSubItems
              ?
              <>
                {/* <div className='nav-menu-item-cont nav-sub-menu-item-cont' style={{ margin: "0 -0.5rem 0 0" }}>
                  <div className='nav-menu-item-overlay nav-sub-menu-item-overlay' style={{ width: "1rem", minWidth: "1rem" }} onClick={handleShowDefaultItems}></div>
                  <div className='nav-menu-item' style={{ width: "1rem", minWidth: "1rem" }}>
                    <div className='nav-menu-item-info nav-sub-menu-item-info'>
                      <MdKeyboardArrowLeft size={28} style={{
                        color: "#ffffff",
                      }} />
                    </div>
                  </div>
                </div> */}

                {/* <div className='nav-menu-item-cont nav-sub-menu-item-cont'>
                  <div className='nav-menu-item-overlay' style={{ width: "1rem", minWidth: "1rem" }} onClick={startSlideShow}></div>
                  <div className='nav-menu-item' style={{ width: "1rem", minWidth: "1rem" }}>
                    <div className='nav-menu-item-info nav-sub-menu-item-info'>
                      {
                        slideShowPlaying
                          ?
                          <IoPauseOutline size={24} style={{
                            // position: "absolute",
                            // zIndex: "3",
                            color: "#ffffff",
                          }} />
                          :
                          <IoPlayOutline size={24} style={{
                            // position: "absolute",
                            // zIndex: "3",
                            color: "#ffffff",
                          }} />
                      }
                    </div>
                  </div>
                </div> */}

                {
                  navigationProp.menuItems.map((data, index) => {
                    return <div className='nav-menu-item-cont'>
                      <div className='nav-menu-item-overlay' id={`item-${index}`} onClick={() => playSubItem(data)}></div>
                      <div key={index} className='nav-menu-item'>
                        <div className='nav-menu-item-info'>
                          <IoPlayOutline size={17} style={{
                            // position: "absolute",
                            // zIndex: "3",
                            color: "rgb(255 255 255 / 80%)",
                            marginBottom: "0.65rem"
                          }} />
                          <div className={`nav-menu-item-title ff-m ${window.innerWidth > 768 ? "fs-xxs" : "fs-xs"}`}>{data}</div>
                        </div>
                        {/* <img key={index} src={navigationProp.defaultMenuIcon[index]} alt={navigationProp.defaultMenu[index]}></img> */}
                      </div>
                    </div>
                  })
                }
              </>
              :
              navigationProp.menuItems.map((data, index) => {
                return <div className='nav-menu-item-cont'>
                  <div key={index} className='nav-menu-item' onClick={() => {

                    if (navigationProp.activeSubMenu === data) {
                      navigationProp.setActiveSubMenu("")
                      return;
                    }
                    else {
                      navigationProp.setActiveSubMenu(data)
                      setTimeout(() => {
                        const submenu = document.querySelector(`.sub-menu-item-cont.visible`);
                        if (submenu) {
                          anime({
                            targets: submenu,
                            translateX: [-50, 0], // Slide from left
                            opacity: [0, 1], // Fade from 0 to 1
                            easing: "easeOutCubic",
                            duration: 300,
                          });
                        }
                      }, 0); // Delay to ensure the submenu is rendered
                    }

                    if (navigationProp.submenuItems[data][0]) playSubItem(navigationProp.submenuItems[data][0])
                    if (data === "Walkthrough Video") playSubItem("Walkthrough Video")
                  }}>
                    <div className='nav-menu-item-info'>
                      <div className={`nav-menu-item-title ff-m ${window.innerWidth > 768 ? "fs-xxs" : "fs-xs"}`}>{data}</div>
                    </div>
                  </div>

                  {/* Sub menu */}
                  {
                    navigationProp.activeSubMenu === data
                      ?
                      <div className='sub-menu-item-cont visible'>
                        {navigationProp.submenuItems[data].map((val, index) => {
                          return <div className='sub-menu-item-div'>
                            {/* <div className='nav-menu-item-overlay'></div> */}
                            <div key={index} className='nav-menu-item' onClick={() => playSubItem(val)} id={`${val.toLowerCase().replace(' ', '')}-id`}>
                              <div className={`nav-menu-item-info ${val === navigationProp.currentSubItem ? 'active' : ''}`}>
                                <div className={`nav-menu-item-title ${val === navigationProp.currentSubItem ? 'ff-sb' : 'ff-m'} ${window.innerWidth > 768 ? "fs-xxs" : "fs-xs"} ${val === navigationProp.currentSubItem ? 'active' : ''}`}>{displaySubMenuTitle(val)}</div>
                              </div>
                            </div>
                          </div>
                        })
                        }
                      </div>
                      :
                      ""
                  }

                </div>
              })
          }
        </div>
      </ScrollContainer>
      {/* <button className='chat-toggler ff-m fs-xxs' onClick={() => setToggleAIChat((prev) => !prev)}>
        {
          toggleAIChat
            ?
            <div>X</div>
            :
            <>
              <span>Ask Sia</span>
              <IoSparkles color='#ffa603' style={{ marginLeft: "0.4rem" }} />
            </>
        }

      </button> */}
    </>
  )
}

export default Navigation