import React, { useState, useEffect, useRef } from 'react';
import { MdHeight } from 'react-icons/md';

const ConfigurationPopup = ({ onClose, onConfigurationSelected }) => {
  const [selectedConfiguration, setSelectedConfiguration] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);

  const handleConfigurationChange = (configuration) => {
    setSelectedConfiguration(configuration);
    setIsAnimating(true);
    setTimeout(() => {
      onConfigurationSelected(configuration);
      onClose();
    }, 500); // Adjust the delay to match the animation duration
  };

  const [shake, setShake] = useState(false);
  const popupRef = useRef(null);

  const handleBackgroundClick = (e) => {
    if (!shake && popupRef.current && !popupRef.current.contains(e.target)) {
      setShake(true);
      setTimeout(() => setShake(false), 500); // Reset after animation
    }
  };


  return (
    <div className='popup-cont' onClick={handleBackgroundClick}>
      <div>
        <style jsx>{`
        .popup-button:active {
          transform: scale(0.95);
        }

        .animate-out {
          opacity: 0;
        }
      `}</style>
        <div style={styles.popupContainer} className={isAnimating ? 'animate-out' : ''} ref={popupRef} className={`popup-container ${shake ? 'shake' : ''}`}>
          <button onClick={onClose} style={styles.closeButton}>
            &times;
          </button>
          <div style={styles.popupContent}>
            <h2 style={styles.title}>Choose your Preferred Configuration</h2>
            <div style={styles.buttonContainer}>
              <button
                onClick={() => handleConfigurationChange('2BHK')}
                style={styles.button}
                className="popup-button"
              >
                2BHK
              </button>
              <button
                onClick={() => handleConfigurationChange('3BHK (Jodi)')}
                style={styles.button}
                className="popup-button"
              >
                3BHK (Jodi)
              </button>
              <button
                onClick={() => handleConfigurationChange('4BHK (Jodi)')}
                style={styles.button}
                className="popup-button"
              >
                4BHK (Jodi)
              </button>
       
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  popupContainer: {
    position: 'fixed',
    fontFamily: 'Clash Display Regular',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    webkitBackdropFilter: 'blur(10px)',
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    zIndex: '10000',
    textAlign: 'center',
    width: '80%',
    color: 'white',
    maxWidth: '20rem',
    height: 'auto',
    transition: 'opacity 0.5s ease-out',
  },
  popupContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: '15px',
    fontSize: '16px',
    color: 'white',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
  },
  button: {
    width: '45%',
    padding: '10px',
    margin: '5px',
    borderRadius: '5px',
    fontSize: '14px',
    fontFamily: 'Clash Display Regular',
    color: 'black',
    backgroundColor: 'beige',
    border: 'none',
    cursor: 'pointer',
    transition: 'transform 0.3s ease-out',
  },
  closeButton: {
    position: 'absolute',
    top: '4px',
    right: '5px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    color: 'white',
  },
};

export default ConfigurationPopup;
