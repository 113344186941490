import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import ModelPreloader from "../Assets/ModelPre";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "../Styles/loadingPage.css";
import CryptoJS from "crypto-js";
import { useLanguage } from "../Assets/LanguageContext";

const UserLogin = (props) => {
  // const { setIsFormSubmitted, mobileScreenHeight, userMobileNumber, userSelectedLanguage, agentId, userName } = props;

  const [isModelPreloaded, setIsModelPreloaded] = useState(false);

  const handleModelPreload = () => {
    setIsModelPreloaded(true);
  };

  const secretKey = "LeapintotheOnline";

  const decodeId = (encoded) => {
    let urlUnsafeEncoded = encoded.replace(/-/g, "+").replace(/_/g, "/");
    const paddingNeeded = 4 - (urlUnsafeEncoded.length % 4);
    if (paddingNeeded && paddingNeeded !== 4) {
      urlUnsafeEncoded += "=".repeat(paddingNeeded);
    }
    const bytes = CryptoJS.AES.decrypt(urlUnsafeEncoded, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const initialOrientation =
    isMobile && window.matchMedia("(orientation: landscape)").matches
      ? "landscape"
      : "other";

  const [orientation, setOrientation] = useState(initialOrientation);

  useEffect(() => {
    const handleOrientationChange = () => {
      const isMobile = /Mobi|Android/i.test(navigator.userAgent);
      if (isMobile && window.matchMedia("(orientation: landscape)").matches) {
        setOrientation("landscape");
      } else {
        setOrientation("other");
      }
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const topLoadingRef = useRef(null);
  // const url = "https://api.ninereflex.xrvizion.com"
  const url = "https://api.ninereflex.xrvizion.com";

  const {
    setIsFormSubmitted,
    mobileScreenHeight,
    userMobileNumber,
    userSelectedLanguage,
    agentId,
    userName,
  } = props;

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState();
  const { setLanguage } = useLanguage();
  const [selectedLanguage, setSelectedLanguage] = useState("en-IN");
  const [isVerifying, setIsVerifying] = useState(false);
  const [showOtp, setShowOtp] = useState(false);

  const [canResendOtp, setCanResendOtp] = useState(false);
  const resendOtpTimer = useRef();
  const [resendTimerCount, setResendTimerCount] = useState(59);
  const [otpSent, setOtpSent] = useState(false);

  const resendOtp = async () => {
    setCanResendOtp(false);
    setOtpSent(true);
    setResendTimerCount(59);
    clearInterval(resendOtpTimer.current);

    topLoadingRef.current.continuousStart();

    try {
      const response = await axios.post(url + "/user/resendotp", {
        mobileNumber: mobile,
      });
      toast.dismiss();
      topLoadingRef?.current?.complete();
    } catch (error) {
      console.error("error submitting resend OTP:", error);
      setTimeout(() => {
        topLoadingRef?.current?.complete();
        toast.dismiss();
        setOtpSent(true);
        toast.error("Request failed", {
          position: window.innerWidth > 768 ? "top-right" : "bottom-center",
          className: window.innerWidth < 768 && "fs-sm",
          style: {
            background:
              "radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)",
          },
        });
      }, 1000);
    }
  };

  const validateMobile = (input) => {
    return isValidPhoneNumber(input);
  };

  useEffect(() => {
    if (resendTimerCount === 0) {
      setCanResendOtp(true);
    }
  }, [resendTimerCount]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateMobile(mobile)) {
      toast.dismiss();
      toast.error("Please enter a valid mobile number", {
        position: window.innerWidth > 768 ? "top-right" : "bottom-center",
        className: window.innerWidth < 768 && "fs-sm",
        style: {
          background:
            "radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)",
        },
      });
      return;
    }

    setIsVerifying(true);
    topLoadingRef.current.continuousStart();

    try {
      const response = await axios.post(url + "/user/setuser", {
        mobileNumber: mobile,
        name: name,
        email: email,
        agentId: agentId === "a1" ? "a1" : agentId,
      });
      toast.dismiss();
      setIsFormSubmitted(true);
      localStorage.setItem("mobile", mobile);
      setIsVerifying(false);
      topLoadingRef?.current?.complete();

      userMobileNumber.current = mobile;
      userName.current = name;
      userSelectedLanguage.current = selectedLanguage;
      localStorage.setItem("userSelectedLanguage", selectedLanguage);
      localStorage.setItem("name", name);
    } catch (error) {
      console.error("error submitting user details:", error);
      setTimeout(() => {
        setIsVerifying(false);
        topLoadingRef?.current?.complete();
        toast.dismiss();
        toast.error("Request failed", {
          position: window.innerWidth > 768 ? "top-right" : "bottom-center",
          className: window.innerWidth < 768 && "fs-sm",
          style: {
            background:
              "radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)",
          },
        });
      }, 1000);
    }
  };

  useEffect(() => {
    if (resendTimerCount === 0) {
      setCanResendOtp(true);
    }
  }, [resendTimerCount]);

  const verifyOtp = async (e) => {
    e.preventDefault();
    setIsVerifying(true);
    topLoadingRef.current.continuousStart();

    try {
      const response = await axios.post(
        url + "/user/verifyotp",
        {
          mobileNumber: mobile,
          verificationToken: otp,
        },
        { withCredentials: true }
      );
      toast.dismiss();
      topLoadingRef?.current?.complete();
      setIsFormSubmitted(true);
      localStorage.setItem("mobile", mobile);
      setIsVerifying(false);
      userSelectedLanguage.current = selectedLanguage;
      localStorage.setItem("userSelectedLanguage", selectedLanguage);
    } catch (error) {
      console.error("error verifying otp:", error);
      setTimeout(() => {
        setIsVerifying(false);
        topLoadingRef?.current?.complete();
        toast.dismiss();
        toast.error("Verification failed", {
          position: window.innerWidth > 768 ? "top-right" : "bottom-center",
          className: window.innerWidth < 768 && "fs-sm",
          style: {
            background:
              "radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(248, 246, 227, 0.8) 0%, #ffffff 100%)",
          },
        });
      }, 1000);
    }
  };

  const handleInputBlur = () => {
    // updateLoadingScreenHeight(); // Adjust loading screen height when input loses focus
  };

  const handleChange = (event) => {
    setSelectedLanguage(event.target.value);
    setLanguage(event.target.value); // Update the context state
  };

  return (
    <>
      <div className="overlay"></div>
      <LoadingBar color="#edd795" ref={topLoadingRef} shadow={true} />
      <ModelPreloader onLoadComplete={handleModelPreload} />

      <div
        className="loading-screen"
        style={{ overflow: orientation === "landscape" ? "scroll" : "hidden" }}
      >
        <div>
          <Toaster />
        </div>
        {isModelPreloaded ? (
          !showOtp ? (
            <form
              onSubmit={handleSubmit}
              className="load-start-cont-new"
              style={{
                justifyContent:
                  orientation === "landscape" ? "normal" : "center",
                height: orientation === "landscape" ? "100%" : "65%",
                top: orientation === "landscape" ? "10%" : "",
                paddingBottom: orientation === "landscape" ? "15%" : "",
              }}
            >
              <div className="ff-m fs-xl title-text">
                Welcome to Nine Reflex
              </div>
              <div className="ff-r fs-sm subtitle-text">
                Enter your name and mobile to begin AI walkthrough!
              </div>
              <div className="note-cont-new form-cont">
                <input
                  required
                  onBlur={handleInputBlur}
                  type="text"
                  className="text-input-new ff-m fs-sm"
                  value={name}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    if (/^[a-zA-Z0-9\s]*$/.test(newValue)) {
                      setName(newValue);
                    }
                  }}
                  placeholder="Name"
                />
                <div className="input-cont">
                  <PhoneInput
                    required
                    className="text-input-new ff-m fs-sm second-input"
                    value={mobile}
                    onChange={setMobile}
                    placeholder="Mobile"
                    defaultCountry="IN"
                  />
                  <input
                    onBlur={handleInputBlur}
                    type="email"
                    className="text-input-new ff-m fs-sm first-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  ></input>
                </div>
                <select
                  className="text-input-new ff-m fs-sm custom-select"
                  required
                  value={selectedLanguage}
                  onChange={handleChange}
                >
                  <option value="en-IN">English</option>
                  <option value="hi-IN">Hindi</option>
                </select>
              </div>
              <button
                className="start-button ff-sb fs-sm"
                disabled={isVerifying}
              >
                {!isVerifying ? (
                  "Enter"
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Please wait...
                  </div>
                )}
              </button>
            </form>
          ) : (
            <form
              onSubmit={verifyOtp}
              className="load-start-cont-new"
              style={{
                justifyContent:
                  orientation === "landscape" ? "normal" : "center",
                height: orientation === "landscape" ? "100%" : "65%",
                top: orientation === "landscape" ? "10%" : "",
                paddingBottom: orientation === "landscape" ? "15%" : "",
              }}
            >
              <div className="ff-m fs-xl title-text">Verification</div>
              <div className="ff-r fs-sm subtitle-text">{`We have sent an OTP to this number: ${mobile}`}</div>
              <div className="note-cont-new form-cont">
                <input
                  required
                  type="number"
                  className="text-input-new ff-m fs-sm"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Code"
                ></input>
              </div>
              <button
                className="start-button ff-sb fs-sm"
                disabled={isVerifying}
              >
                {!isVerifying ? (
                  "Verify"
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Verifying...
                  </div>
                )}
              </button>
              {!otpSent && (
                <div
                  className="ff-r fs-sm subtitle-text"
                  onClick={() => {
                    if (canResendOtp) {
                      resendOtp();
                    }
                  }}
                  style={{
                    marginTop: "1.5rem",
                    cursor: canResendOtp ? "pointer" : "not-allowed",
                  }}
                >{`Resend OTP ${
                  canResendOtp ? `?` : `in ${resendTimerCount} secs`
                }`}</div>
              )}
            </form>
          )
        ) : (
          <div className="loading-message">
            <p>Loading model, please wait...</p>
          </div>
        )}

        <div
          className="xrv-logo-new"
          style={{ display: orientation === "landscape" ? "none" : "block" }}
        >
          <div className="xrv-logo-text-new ff-l fs-xs">powered by</div>
          <img
            className="xrv-logo-img-new"
            src="https://d3f67ez5tfvtll.cloudfront.net/XRVizion/xrv.png"
            alt=""
          ></img>
        </div>
      </div>
    </>
  );
};

export default UserLogin;
