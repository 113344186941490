import React, { useRef, useState } from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { FaArrowRight } from "react-icons/fa6";

const LocationPopup = ({ onClose, onPlaceSelected }) => {
  const [shake, setShake] = useState(false);
  const popupRef = useRef(null);

  const handleBackgroundClick = (e) => {
    if (!shake && popupRef.current && !popupRef.current.contains(e.target)) {
      setShake(true);
      setTimeout(() => setShake(false), 500); // Reset after animation
    }
  };

  const [autocomplete, setAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isOkayEnabled, setIsOkayEnabled] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const handlePlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      console.log('Selected place:', place.formatted_address);
      setSelectedPlace(place);
      setInputValue(place.formatted_address);
      setIsOkayEnabled(true);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setIsOkayEnabled(event.target.value.trim() !== '');
  };

  const handleOkayClick = () => {
    if (isOkayEnabled) {
      onClose();
      onPlaceSelected(selectedPlace); // Pass the selected place to the parent component
    }
  };

  return (
    <div className='popup-cont' onClick={handleBackgroundClick}>
      <div style={styles.popupContainer} ref={popupRef} className={`popup-container ${shake ? 'shake' : ''}`}>
        <button onClick={onClose} style={styles.closeButton}>
          &times;
        </button>
        <div style={styles.popupContent}>
          <h2 style={styles.title}>Enter your location</h2>
          <LoadScript
            googleMapsApiKey="AIzaSyBo_i5RM7orGl9jfspQmC-UVr7qCZOfaas"
            libraries={['places']}
          >
            <div style={styles.inputContainer}>
              <Autocomplete
                onLoad={setAutocomplete}
                onPlaceChanged={handlePlaceChanged}
              >
                <input
                  type="text"
                  className='text-input'
                  value={inputValue}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </Autocomplete>
              <button
                onClick={handleOkayClick}
                style={isOkayEnabled ? styles.okayButton : styles.okayButtonDisabled}
                disabled={!isOkayEnabled}
              >
                <FaArrowRight />
              </button>
            </div>
          </LoadScript>
        </div>
      </div>
    </div>
  );
};

const styles = {
  popupContainer: {
    position: 'fixed',
    fontFamily: 'Clash Display Regular',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    webkitBackdropFilter: 'blur(10px)',
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    zIndex: '10000',
    textAlign: 'center',
    width: '80%',
    color: 'white',
    maxWidth: '20rem',
    height: 'auto',
  },
  popupContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: '10px',
    fontSize: '18px',
    marginRight: '6rem',
    color: 'white',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '90%',
  },
  input: {
    flex: 1,
    padding: '5px',
    marginBottom: '10px',
    marginTop: '10px',
    borderRadius: '5px', // Reduced border radius
    fontSize: '14px',
    fontFamily: 'Clash Display Regular',
    color: 'beige',
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    border: 'none',
  },
  okayButton: {
    marginLeft: '2rem',
    padding: '5px',
    backgroundColor: 'beige',
    color: 'black',
    border: 'none',
    borderRadius: '50%', // Round shape
    cursor: 'pointer',
    fontSize: '14px',
    fontFamily: 'Clash Display Regular',
    fontWeight: 'bolder',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  okayButtonDisabled: {
    marginLeft: '2rem',
    padding: '5px',
    backgroundColor: 'beige',
    color: 'black',
    border: 'none',
    borderRadius: '50%', // Round shape
    cursor: 'not-allowed',
    fontSize: '14px',
    fontFamily: 'Clash Display Regular',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    color: 'white',
  },
};

export default LocationPopup;
