import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { PostHogProvider } from 'posthog-js/react'

const options = {
  api_host: "https://eu.i.posthog.com",
  person_profiles: 'always'
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PostHogProvider
    apiKey={"phc_h7H3zTKGFLoCyRqmSEZDTsPpXdugIjRzrmJhdBfo2II"}
    options={options}
  >
    <App />
  </PostHogProvider>
);