import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-multi-date-picker";
import { FaCheck, FaAngleDown } from "react-icons/fa";

const CustomTimePicker = ({ value, onChange }) => {
  const [time, setTime] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleTimeChange = (newTime) => {
    setTime(newTime);
    onChange(newTime);
    setIsOpen(false);
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 10; hour <= 20; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const period = hour < 12 ? "AM" : "PM";
        const displayHour = hour % 12 === 0 ? 12 : hour % 12;
        const displayTime = `${displayHour}:${minute < 10 ? `0${minute}` : minute} ${period}`;
        options.push(
          <div
            key={`${hour}:${minute}`}
            onClick={() => handleTimeChange(displayTime)}
            className="time-option ff-r"
            style={{ fontSize: '.8rem' }}
          >
            {displayTime}
          </div>
        );
      }
    }
    return options;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isMobile = typeof window !== 'undefined' ? window.innerWidth < 768 : false;

  return (
    <div className="custom-time-picker-container site-booking-btn" ref={dropdownRef}>
      <div
        className="selected-time fs-xs"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          height: isMobile ? '14px' : '21px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontFamily: 'Clash Display Regular'
        }}
      >
        {time || "Select Time"} &nbsp; <FaAngleDown />
      </div>
      {isOpen && (
        <div className="time-dropdown" style={{ maxHeight: '150px', overflowY: 'auto' }}>
          {generateTimeOptions()}
        </div>
      )}
      <style>{`
        .custom-time-picker-container {
          position: relative;
          font-family: 'Clash Display Regular';
          margin-left: ${isMobile ? '0.2rem' : '1rem'};
        }

        .time-dropdown {
          position: absolute;
          bottom: 100%;
          left: 0;
          right: 0;
          background: rgba(72, 67, 67, 0.67);
          color: white;
          border-radius: 9px;
          max-height: 150px;
          overflow-y: auto;
          z-index: 1000;
          border: 1px solid #c0c4d6;
          font-family: 'Clash Display Regular';
        }

        .time-option {
          padding: 4px 5px;
          cursor: pointer;
          font-family: 'Clash Display Regular';
          font-size: ${isMobile ? '9px' : '11px'};
        }

        .time-option:hover {
          background: #0074d9;
          color: white;
        }

        .time-dropdown::-webkit-scrollbar {
          width: 8px;
        }

        .time-dropdown::-webkit-scrollbar-track {
          background: rgba(72, 67, 67, 0.7);
          color: white;
        }

        .time-dropdown::-webkit-scrollbar-thumb {
          background: rgba(72, 67, 67, 0.7);
          color: white;
        }

        .time-dropdown::-webkit-scrollbar-thumb:hover {
          background: rgba(72, 67, 67, 0.7);
          color: white;
        }
      `}</style>
    </div>
  );
};

const DayDatePicker = ({ siteBookingDate, handleSiteBooking, setSiteBookingDate, datePickerRef }) => {
  const [selectedTime, setSelectedTime] = useState("");
  const isMobile = typeof window !== 'undefined' ? window.innerWidth < 768 : false;
  const [isFormVisible, setIsFormVisible] = useState(true);

  useEffect(() => {
    setSiteBookingDate(new Date());
  }, [setSiteBookingDate]);

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleBooking = async (e) => {
    e.preventDefault();
  
    // Create the combined date time
    const combinedDateTime = new Date(siteBookingDate);
    if (selectedTime) {
      const [time, period] = selectedTime.split(" ");
      const [hours, minutes] = time.split(":");
      const adjustedHours = period === "PM" ?
        (parseInt(hours) === 12 ? 12 : parseInt(hours) + 12) :
        (parseInt(hours) === 12 ? 0 : parseInt(hours));
      combinedDateTime.setHours(adjustedHours);
      combinedDateTime.setMinutes(parseInt(minutes));
    } else {
      combinedDateTime.setHours(10);
      combinedDateTime.setMinutes(0);
    }
    combinedDateTime.setSeconds(0);
  
    // Call the booking handler
    handleSiteBooking(combinedDateTime);
  
    // Hide the form
    setIsFormVisible(false);
  };
  

  return (
    <>
      {isFormVisible && (
        <form onSubmit={handleBooking} className="site-booking-cont">
          <div
            className="site-booking-title ff-r fs-sm"
            style={{ fontSize: isMobile ? '10px' : '16px' }}
          >
            {isMobile ? 'Choose slot' : 'Select date and time'}
          </div>
          <div className="site-booking-action" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <DatePicker
              ref={datePickerRef}
              className={`bg-dark ff-r fs-sm ${isMobile ? 'rmdp-mobile' : ''}`}
              format="DD/MM/YY"
              type="input"
              minDate={new Date()}
              value={siteBookingDate}
              highlightToday={false}
              showOtherDays={true}
              onChange={setSiteBookingDate}
            />
            <CustomTimePicker value={selectedTime} onChange={handleTimeChange} className='bg-dark' />
            <div style={{ marginLeft: isMobile ? "0.5rem" : "1rem" }}>
           <button className="ff-r fs-sm site-booking-btn" onClick={handleBooking}>
            <FaCheck />
          </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
  
};

export default DayDatePicker;
